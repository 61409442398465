import React, {useEffect, useContext, useMemo, useRef, useState, useCallback} from 'react'
import Peer from 'simple-peer'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Entity from '../Entity'
import {SocketContext} from '../../socket'
import {makeStyles, useTheme} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    entity: {
        display: 'none',
        [theme.breakpoints.up('xl')]: {
            display: 'block'
        }
    },
    video: {
        display: 'none',
        [theme.breakpoints.up('xl')]: {
            display: 'block',
            width: 'auto',
            height: '100%'
        },
        width: 'auto',
        maxHeight: "1200px"
    }
}))

const ScreenShare = ({isInitiator, setIsInitiator}) => {
    const theme = useTheme();
    const classes = useStyles();
    const socket = useContext(SocketContext)
    const displayBreakpoint = useMediaQuery(theme.breakpoints.up('xl'));
    const [peer, setPeer] = useState(null)
    const [isConnected, setIsConnected] = useState(false)
    const [video, setVideo] = useState(null)
    const videoRef = useRef()



    useEffect(() => {
        (displayBreakpoint || isInitiator) &&
            setPeer(new Peer({initiator: isInitiator, trickle: false})) // config: {'iceServers': [{ 'urls': 'turn:turn.samuelchabot.com:5349' }]}}),
    }, [isInitiator, displayBreakpoint])

    useEffect(() => {
        if (socket) {
            socket.on('screen_share_signal', data => {
                console.log('received socket data', !isConnected, data)
                peer && !isConnected && peer.signal(data)
            })
        }
        return () => {socket.off('screen_share_signal')}
    }, [socket, peer, isConnected])


    useEffect(() => {
        const cleanUpPeer = () => {
            console.log('ENDED STREAM')
            peer && peer.destroy()
            isInitiator && setIsInitiator(null)
            displayBreakpoint ? setPeer(new Peer()) : setPeer(null)
            setIsConnected(false)
            setVideo(null)
            if (videoRef.current) {
                videoRef.current.srcObject = null;
            }
        }
        if (peer) {
            peer.on('signal', data => {
                console.log('SIGNAL')
                socket.emit('screen_share_signal', data)
            })
            peer.on('connect', () => {
                console.log('isConnected')
                // setIsConnected(true)
                // SAFARI: need to call getDisplayMedia() from explicit
                // user input (i.e. button onClick event)
                isInitiator && navigator.mediaDevices.getDisplayMedia()
                    .then(stream => {
                        console.log('ADDING STREAM', stream, 'to\n', peer);
                        peer.addStream(stream);
                        stream.getVideoTracks()[0].onended = cleanUpPeer
                    })
            })
            peer.on('stream', stream => {
                console.log('VIDEO STREAM', stream)
                setVideo(stream)
                if (videoRef.current) {
                    videoRef.current.srcObject = stream;
                }
            })
            peer.on('error', data => {
                console.log('peer error', data)
            })
            peer.on('close', cleanUpPeer)
        }
    }, [socket, peer, isInitiator, setIsInitiator, displayBreakpoint])

    const displayShareEntity = useCallback(() => {
        return {display: (displayBreakpoint && video) ? 'block' : 'none'}
    }, [displayBreakpoint, video])

    return (
        <div style={displayShareEntity()}>
            <Entity entity={{user: {displayName: "Screen Share"}, file: {}}}>
                <video ref={videoRef} className={classes.video} autoPlay />
            </Entity>
        </div>
    )
}









// const ScreenShare = ({user, isInitiator, setisInitiator}) => {
//     const classes = useStyles()
//     const theme = useTheme();

//     const socket = useContext(SocketContext)
//     // const initiator = Boolean(role === "initiator")
//     const peer = useMemo(() => new Peer({initiator: isInitiator}), [isInitiator]) // config: {'iceServers': [{ 'urls': 'turn:turn.samuelchabot.com:5349' }]}}),
//     const video = useRef()
//     const [shareEntity, setShareEntity] = useState(null)

//     const displayBreakpoint = useMediaQuery(theme.breakpoints.up('xl'));

//     useEffect(() => {
//         setShareEntity({user: {photoURL: user.picture, displayName: user.name}})
//     }, [user])

//     useEffect(() => {

//     }, [displayBreakpoint])

//     useEffect(() => {
//         if (socket) {
//             socket.on('screen_share_signal', data => {
//                 console.log('received scoket data', data)
//                 displayBreakpoint && peer.signal(data)
//             })

//             return () => {
//                 socket.off("screen_share_signal");
//             }
//         }
//     }, [socket, peer, displayBreakpoint])

//     useEffect(() => {
//         if (peer) {
//             peer.on('signal', data => {
//                 console.log('peer signal', data)
//                 socket.emit('screen_share_signal', data)
//             })
//             peer.on('error', data => {
//                 console.log('peer error', data)
//             })
//             peer.on('connect', data => {
//                 console.log('isConnected')
//                 if (isInitiator) {
//                     navigator.mediaDevices.getDisplayMedia()
//                         .then(s => {
//                             peer.addStream(s);
//                         })
//                 }
//             })
//             peer.on('close', () => {
//                 if (video.current) {
//                     video.current.srcObject = null;
//                 }
//             })
//             peer.on('stream', stream => {
//                 console.log('here', video.current)
//                 // if (video.current) {video.current.srcObject = stream;}
//                 if (video.current) {
//                     video.current.srcObject = stream;
//                 }
//             })
//         }
//     }, [socket, peer, isInitiator])


//     return (
//         // (!initiator && video.current && video.current.srcObject && 
//         // <Dragger>
//         <div>
//             {shareEntity && video && video.current && video.current.srcObject &&
//                 <Entity
//                     entity={shareEntity}
//                     id={`screen-share`}
//                     // removeEntity={removeEntity}
//                     key={`screen-share`}
//                 >
//                     <video ref={video} className={classes.video} autoPlay />
//                 </Entity>
//             }
//         </div>

//         // <video ref={video} className={classes.video} autoPlay />
//         // </Dragger>
//     )
// }

export default ScreenShare
