import React, {useState, useEffect, useRef} from 'react'
import * as firebaseui from 'firebaseui'
import firebase from 'firebase/app'
import {withFirebase} from '../Firebase'
import 'firebaseui/dist/firebaseui.css'

// const SignInPage = () => (
//     <div>
//         <h1>SignIn</h1>
//         <SignIn />
//     </div>
// );


const SignInBase = () => {
    const uiConfig = ({
        signInSuccessUrl: '/',
        signInFlow: 'popup',
        signInOptions: [
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            firebase.auth.FacebookAuthProvider.PROVIDER_ID,
            firebase.auth.EmailAuthProvider.PROVIDER_ID,
        ],
        tosUrl: '/terms-of-service' // This doesn't exist yet
    })

    const ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(firebase.auth())

    const startFirebaseUI = function (elementId) {
        ui.start(elementId, uiConfig)
    }

    const firebaseSignIn = useRef()

    useEffect(() => {
        startFirebaseUI('#firebase-signin')
    }, [])

    return (
        <div ref={firebaseSignIn} id='firebase-signin'></div>
    )
}

// const SignInBase = ({error, firebase}) => {
//     const login = (username, password) => {
//         return new Promise((resolve, reject) => {
//             firebase
//                 .doSignInWithEmailAndPassword(username, password)
//                 .then(() => resolve(console.log('SIGNED IN')))
//                 .catch(error => reject(error));
//         });
//     }
//     const [username, setUsername] = useState("");
//     const [password, setPassword] = useState("");
//     return (
//         <div>
//             <input
//                 onChange={event => {
//                     setUsername(event.target.value);
//                 }}
//             />
//             <input
//                 type="password"
//                 onChange={event => {
//                     setPassword(event.target.value);
//                 }}
//             />
//             <button
//                 onClick={() => {
//                     login(username, password);
//                 }}
//             >
//                 Login
//         </button>
//             <span>{error}</span>
//         </div>
//     );
// }

const SignIn = withFirebase(SignInBase);

export default SignIn

// export {SignIn}