import React, {useState, useContext} from 'react';
import {NavLink} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import {AppBar, Button, IconButton, Menu, MenuItem, Toolbar, Typography} from '@material-ui/core'
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuIcon from '@material-ui/icons/Menu';

import SignOutButton from '../SignOut'
import * as ROUTES from '../../constants/routes';
import {AuthUserContext} from '../Session';


const useStyles = makeStyles((theme) => ({
    grow: {flexGrow: 1},
    appBar: {position: 'sticky'},
    appBarShow: {background: theme.palette.background.default},
    appBarHidden: {backgroundColor: 'transparent'},
    accountPhoto: {
        borderRadius: '100%',
        cursor: 'pointer',
        maxWidth: '100%',
        maxHeight: '50px',
        paddingTop: theme.spacing(),
        paddingBottom: theme.spacing(),
        transition: `0.4s`,
        '&:hover': {
            transform: `scale(1.1, 1.1)`
        }
    },
    activeLink: {
        color: `${theme.palette.highlight.main} !important`
    },
    button: {
        textDecoration: 'none',
        background: theme.palette.primary.main,
        color: theme.palette.secondary.main,
        marginRight: theme.spacing(2),
        '&:hover': {
            textDecoration: 'none',
            background: theme.palette.primary.main,
            color: theme.palette.secondary.main,
        }
    },
    craiveTitle: {
        textDecoration: 'none',
        paddingRight: theme.spacing(2),
        color: theme.palette.highlight.main,
    },
    header: {
        ...theme.typography.header,
    },
    icon: {
        color: theme.palette.primary.default
    },
    link: {
        textDecoration: 'none',
        paddingRight: theme.spacing(2),
        color: theme.palette.primary.main,
        "&:hover": {
            color: theme.palette.highlight.main,
        }
    },
    menuLink: {
        textDecoration: 'none',
        color: theme.palette.secondary.main
    },
    sectionFullscreen: {
        // display: 'flex',
        [theme.breakpoints.up('xl')]: {
            display: 'none',
        }
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
}))

const Navigation = () => (
    <AuthUserContext.Consumer>
        {user =>
            user ? <NavigationAuth /> : <NavigationNonAuth />
        }
    </AuthUserContext.Consumer>
);

const NavigationAuth = () => {
    const classes = useStyles();
    const user = useContext(AuthUserContext)

    const [profileAnchorEl, setProfileAnchorEl] = useState(null);
    const [demoAnchorEl, setDemoAnchorEl] = useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);

    const isProfileMenuOpen = Boolean(profileAnchorEl);
    const isDemoMenuOpen = Boolean(demoAnchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleProfileMenuOpen = (event) => {
        setProfileAnchorEl(event.currentTarget);
    };

    const handleProfileMenuClose = () => {
        setProfileAnchorEl(null);
    };

    const handleDemoMenuOpen = (event) => {
        setDemoAnchorEl(event.currentTarget);
    };

    const handleDemoMenuClose = () => {
        setDemoAnchorEl(null);
    };

    const profileMenu = (
        <Menu
            anchorEl={profileAnchorEl}
            anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
            getContentAnchorEl={null}
            id={'profileMenu'}
            keepMounted
            transformOrigin={{vertical: 'top', horizontal: 'right'}}
            open={isProfileMenuOpen}
            onClose={handleProfileMenuClose}
        >
            <MenuItem>
                <SignOutButton />
            </MenuItem>


        </Menu>
    );

    const mobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
            getContentAnchorEl={null}
            id={'nav-menu-mobile'}
            keepMounted
            transformOrigin={{vertical: 'top', horizontal: 'right'}}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            {/* <MenuItem> */}
            {/* <NavLink to={ROUTES.LANDING}>
                    <Typography>Landing</Typography>
                </NavLink>
                </MenuItem> */}
            <MenuItem>
                <NavLink to={ROUTES.HOME} className={classes.menuLink}>
                    <Typography>Home</Typography>
                </NavLink>
            </MenuItem>
            <MenuItem>
                <NavLink to={ROUTES.DASHBOARD} className={classes.menuLink}>
                    <Typography>Dashboard</Typography>
                </NavLink>
            </MenuItem>
            <MenuItem>
                <NavLink to={ROUTES.PIN_UP} className={classes.menuLink}>
                    <Typography>Pin-Up</Typography>
                </NavLink>
            </MenuItem>
            <MenuItem>
                <NavLink
                    to={ROUTES.STREETVIEW} className={classes.menuLink}>
                    <Typography>Street View</Typography>
                </NavLink>
            </MenuItem>
        </Menu>
    );

    const demoMenu = (
        <Menu anchorEl={demoAnchorEl}
            anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
            getContentAnchorEl={null}
            id={'demo-menu'}
            keepMounted
            transformOrigin={{vertical: 'top', horizontal: 'right'}}
            open={isDemoMenuOpen}
            onClose={handleDemoMenuClose}
        >
            <MenuItem>
                <NavLink to={ROUTES.DEMOS} className={classes.menuLink}>
                    <Typography>Cistern</Typography>
                </NavLink>
            </MenuItem>
            <MenuItem>
                <NavLink to={ROUTES.DEMOS} className={classes.menuLink}>
                    <Typography>Cologne</Typography>
                </NavLink>
            </MenuItem>
        </Menu>
    )

    return (
        <AppBar className={`${classes.appBar} ${classes.appBarShow} ${classes.sectionFullscreen}`}>
            <Toolbar>
                <NavLink className={classes.craiveTitle} to={ROUTES.LANDING}>
                    <Typography variant='h5' className={classes.header}>The CRAIVE-Lab @ Rensselaer</Typography>
                </NavLink>
                <div className={classes.grow} />

                <div className={classes.sectionDesktop}>

                    {/* <NavLink className={classes.link} to={ROUTES.LANDING}>
                        <Typography>Landing</Typography>
                    </NavLink> */}

                    {/* <NavLink
                        to={ROUTES.HOME}
                        activeClassName={classes.activeLink}
                        className={classes.link}
                    >
                        <Typography variant='h6' className={classes.header}>Home</Typography>
                    </NavLink> */}

                    <NavLink
                        to={ROUTES.DASHBOARD}
                        activeClassName={classes.activeLink}
                        className={classes.link}
                    >
                        <Typography variant='h6' className={classes.header}>Dashboard</Typography>
                    </NavLink>

                    <NavLink
                        to={ROUTES.DEMOS}
                        activeClassName={classes.activeLink}
                        className={classes.link}
                    >
                        <Typography variant='h6' className={classes.header}>Demos</Typography>
                    </NavLink>

                    {/* <Typography
                        to={ROUTES.DEMOS}
                        activeClassName={classes.activeLink}
                        className={classes.link}
                        // style={{cursor: 'pointer'}}
                        onClick={handleDemoMenuOpen}
                    // onMouseEnter={handleDemoMenuOpen}
                    // onMouseOver={handleDemoMenuOpen}
                    // onMouseLeave={handleDemoMenuClose}
                    >
                        Demos
                        </Typography> */}

                    <NavLink to={ROUTES.PIN_UP} activeClassName={classes.activeLink} className={classes.link}>
                        <Typography variant='h6' className={classes.header}>Pin-Up</Typography>
                    </NavLink>

                    <NavLink
                        to={ROUTES.STREETVIEW}
                        activeClassName={classes.activeLink}
                        className={classes.link}
                    >
                        <Typography variant='h6' className={classes.header}>Street View</Typography>
                    </NavLink>

                    {user && (user.claims.admin || user.claims.manage) &&
                        <NavLink
                            to={ROUTES.MANAGE}
                            activeClassName={classes.activeLink}
                            className={classes.link}
                        >
                            <Typography variant='h6' className={classes.header}>Manage</Typography>
                        </NavLink>
                    }
                </div>
                <div className={classes.sectionMobile}>
                    <IconButton
                        edge="start"
                        className={classes.icon}
                        onClick={handleMobileMenuOpen}
                    >
                        <MenuIcon />
                    </IconButton>

                </div>
                {profileMenu}
                {mobileMenu}
                {demoMenu}
                {user.claims.picture ?
                    <img className={classes.accountPhoto} src={user.claims.picture} onClick={handleProfileMenuOpen} alt='nope' />
                    :
                    <IconButton
                        edge="end"
                        onClick={handleProfileMenuOpen}
                        color="inherit"
                    >
                        <AccountCircle />
                    </IconButton>}
                {/* <SignOutButton /> */}
            </Toolbar>
        </AppBar>
    )
}

const NavigationNonAuth = () => {
    const classes = useStyles();
    return (
        <AppBar className={`${classes.appBar} ${classes.appBarHidden}`}>
            <Toolbar>
                <NavLink className={classes.craiveTitle} to={ROUTES.LANDING}>
                    <Typography variant='h5' className={classes.header}>The CRAIVE-Lab @ Rensselaer</Typography>
                </NavLink>
                {/* <NavLink to={ROUTES.LANDING}>Landing</NavLink> */}
                <div className={classes.grow} />
                <NavLink className={classes.link} to={ROUTES.SIGN_IN}><Button className={classes.button}><Typography>Sign In</Typography></Button></NavLink>
            </Toolbar>
        </AppBar>
    )
}

export default Navigation;