import React, {useEffect} from 'react'
import {makeStyles} from '@material-ui/core/styles';
import {Typography} from '@material-ui/core';
import {useDropzone} from 'react-dropzone';

const useStyles = makeStyles((theme) => ({
    dropzone: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: theme.palette.highlight.main,
        borderStyle: 'dashed',
        backgroundColor: theme.palette.background.main,
        color: theme.palette.highlight.main,
        outline: 'none',
        transition: 'border .24s ease-in-out',
        height: 250,
        width: '100%'
    },

}))


const DropZone = ({setFiles}) => {
    const classes = useStyles();
    const {acceptedFiles, getRootProps, getInputProps} = useDropzone();

    useEffect(() => {
        setFiles(acceptedFiles)
    }, [acceptedFiles])

    return (
        <section className="dropzoneContainer">
            <div {...getRootProps({className: classes.dropzone})}>
                <input {...getInputProps()} />
                <Typography>Drag 'n' drop or tap to send directly to display screen</Typography>
            </div>
        </section>
    );
}

export default DropZone