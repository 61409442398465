import firebase from 'firebase/app';
import 'firebase/auth'
import config from '../../config'

class Firebase {
    constructor() {
        firebase.initializeApp(config.firebase);
        this.auth = firebase.auth();
    }

    doCreateUserWithEmailAndPassword = (email, password) =>
        this.auth.createUserWithEmailAndPassword(email, password);

    doSignInWithEmailAndPassword = (email, password) =>
        this.auth.signInWithEmailAndPassword(email, password);

    doSignOut = () => this.auth.signOut();

    doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

    doPasswordUpdate = password =>
        this.auth.currentUser.updatePassword(password);
}


export default Firebase;