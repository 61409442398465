import React, {useState, useEffect, useContext} from 'react'
import {BrowserRouter as Router, Route} from 'react-router-dom'
import {makeStyles, useTheme} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {AuthUserContext, withAuthentication} from './Components/Session'
import {WithSocket} from './socket';
import * as ROUTES from './constants/routes';
import useScript from "./hooks/useScript"

import PrivateRoute from './Components/PrivateRoute'
import PublicRoute from './Components/PublicRoute'
import Navigation from './Components/Navigation';
import LandingPage from './Components/Landing';
import PinUp from './Components/PinUp';
import Demos from './Components/Demos';
import Streetview from './Components/Streetview';
// import SignUpPage from './Components/SignUp';
import SignIn from './Components/SignIn';
// import PasswordForgetPage from './Components/PasswordForget';
// import HomePage from './Components/Home';
// import AccountPage from './Components/Account';
// import AdminPage from './Components/Admin';
import Manage from './Components/Manage'
import Dashboard from './Components/Dashboard';
import './App.css';

const useStyles = makeStyles((theme) => ({
  paddingBar: {
    display: 'block',
    [theme.breakpoints.up('xl')]: {
      display: 'none',
    },
    ...theme.mixins.toolbar,
  },
}))


const App = () => {
  const user = useContext(AuthUserContext)
  const theme = useTheme();
  const classes = useStyles();

  // for PDF Embed API
  useScript("https://documentcloud.adobe.com/view-sdk/main.js")

  const displayBreakpoint = useMediaQuery(theme.breakpoints.up('xl'));

  // THIS ALTERS CURSOR FOR DISPLAY
  // CURSOR BECOMES FLOATING CIRCLE
  useEffect(() => {
    displayBreakpoint ?
      document.documentElement.style.cursor = "none"
      :
      document.documentElement.style.cursor = "default"
  }, [displayBreakpoint])

  return (
    <WithSocket>
      <Router>
        <div>
          <Navigation />
          {/* <div className={classes.paddingBar} /> */}
          {/* <hr /> */}

          {/* <Route path={ROUTES.SIGN_UP} component={SignUpPage} /> */}
          <PublicRoute restricted={true} exact path={ROUTES.SIGN_IN} component={SignIn} />
          <PublicRoute exact restricted={false} path={ROUTES.LANDING} component={LandingPage} />

          <PrivateRoute path={ROUTES.PIN_UP} component={PinUp} />
          {/* <PrivateRoute path={ROUTES.DEMOS} component={Demos} /> */}
          {/* <PrivateRoute path={ROUTES.STREETVIEW} component={Streetview} /> */}
          {user && (user.claims.admin || user.claims.manager) && <PrivateRoute path={ROUTES.MANAGE} component={Manage} />}
          {/* <Route path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage} /> */}
          {/* <Route path={ROUTES.HOME} component={HomePage} /> */}
          {/* <Route path={ROUTES.ACCOUNT} component={AccountPage} /> */}
          {/* <Route path={ROUTES.ADMIN} component={AdminPage} /> */}
          {/* <PrivateRoute path={ROUTES.DASHBOARD} component={Dashboard} /> */}
        </div>
      </Router>
    </WithSocket>
  );
}

export default withAuthentication(App);
