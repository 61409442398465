import {createMuiTheme} from '@material-ui/core/styles';

// A custom theme for this app
const theme = createMuiTheme({
    palette: {
        background: {
            default:
                // '#2f2c2c'
                '#363636'
        },
        primary: {
            default: '#dddddd',
            main: '#dddddd'
        },
        secondary: {
            default: '#1f1c1c',
            main: '#1f1c1c'
        },
        highlight: {
            default: '#F2F2F2',
            main: '#F2F2F2'
        }
    },
    typography: {
        header: {
            fontFamily: [
                'Bebas Neue, cursive'
            ].join(',')
        },
        fontFamily: [
            // 'objektiv-mk1, sans-serif'
            // 'Assistant, sans-serif'
            'proxima-nova, sans-serif'

            // 'objektiv-mk1, sans-serif'
        ].join(','),
    },
    shadows: ["none"],
    props: {
        // Name of the component ⚛️
        MuiButtonBase: {
            // The properties to apply
            disableRipple: true, // No more ripple, on the whole application 💣!
        },
    },
    overrides: {
        MuiSlider: {
            root: {
                // padding: '15px 0',
                color: '#3880ff',
                // height: 24,

            },
            thumb: {
                backgroundColor: '#fff',
                width: 16,
                height: 16,
                marginTop: 0,
                marginBottom: "-8px !important",
                marginLeft: "-2px !important",
                '&:focus, &:hover, &$active': {
                    boxShadow: 'none',
                },

            },
            track: {
                width: "12px !important",
                borderRadius: 12,
                // border: `1px solid #777`
            },
            rail: {
                // height: 20,
                width: "12px !important",
                borderRadius: 12,
                // borderRadius: 4,
            },
        },
        MuiSwitch: {
            root: {
                width: 42,
                height: 26,
                padding: 0,
            },
            switchBase: {
                padding: 1,

                // Controls default (unchecked) color for the thumb
                color: "#EEE",
                opacity: 0.7
            },
            colorSecondary: {
                "&$checked": {
                    transform: 'translateX(16px)',

                    // Controls checked color for the thumb
                    color: "#FFF",
                    opacity: 1
                }
            },
            thumb: {
                width: 24,
                height: 24
            },
            track: {
                borderRadius: 26 / 2,
                border: `1px solid #777`,
                // Controls default (unchecked) color for the track
                opacity: 0.2,
                backgroundColor: "#fff",
                "$checked$checked + &": {
                    // Controls checked color for the track
                    opacity: 0.7,
                    backgroundColor: "#3456ac"
                }
            }
        }
    }
});

export default theme;