import React, {useState, useEffect, useRef} from "react";
import {makeStyles} from '@material-ui/core/styles';
import {IconButton} from "@material-ui/core";
import MapIcon from '@material-ui/icons/Map';
import InfiniteScroll from "../InfiniteScroll";
import config from "../../config";

const useStyles = makeStyles((theme) => ({
    panoTable: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "200%",
        height: "140%",
        marginBottom: "0rem",
        backgroundColor: "#565656",
        border: "none",
        borderCollapse: "collapse",
    },
    // scrollTable: {
    //     position: "fixed",
    //     top: 0,
    //     left: 0,
    //     width: "100%",
    //     height: '100%',
    //     overflow: "scroll"
    // },
    streetviewButton: {
        width: 30,
        height: 30,
        zIndex: 4,
        position: "absolute",
        bottom: theme.spacing(7),
        left: theme.spacing(3),
        borderRadius: theme.spacing,
        backgroundColor: theme.palette.primary.default,
        cursor: "none"
    },
    td: {
        backgroundPosition: "center",
        backgroundSize: "100% 100%",
        backgroundRepeat: "no-repeat",
        imageRendering: "-moz-crisp-edges", /* Firefox */
        imageRendering: "-o-crisp-edges", /* Opera */
        imageRendering: "-webkit-optimize-contrast", /* Webkit (non-standard naming) */
        transitionDelay: "0.75s",
        transition: "background-image 2s ease-in-out",
        border: "none",
        padding: 0,
        margin: 0
    }
}))

const Streetview = ({lat, lng, panoCode, setFullscreen}) => {
    const classes = useStyles();
    const [panoInfo, setPanoInfo] = useState(null)
    const scrollDiv = useRef(false)
    const [windowWidth, setWindowWidth] = useState(-1);

    const handleResize = () => setWindowWidth(window.innerWidth)

    useEffect(() => {
        setPanoInfo(lat ? `location=${lat},${lng}` : `pano=${panoCode}`)
        // handleResize()
        // window.addEventListener('resize', handleResize);
        // return () => window.removeEventListener('resize', handleResize);
    }, [])


    const headings = [0, 40, 80, 120, 160, 200, 240, 280, 320];

    const panoPieces = headings.map((heading, i) => (
        panoInfo &&
        <td
            className={classes.td}
            key={`image-${i}`}
            id={`image-${i}`}
            style={{
                backgroundImage: `url(https://maps.googleapis.com/maps/api/streetview?size=1024x2048&${panoInfo}%0A&fov=40&heading=${heading}&pitch=0%0A&key=${config.google_maps_api_key})`
            }}
        />
    ));

    // const onScroll = e => {
    //     const currentScrollX = e.target.scrollLeft
    //     if (scrollDiv && scrollDiv.current && currentScrollX === windowWidth) scrollDiv.current.scrollLeft = 0
    //     if (scrollDiv && scrollDiv.current && currentScrollX === 0) scrollDiv.current.scrollLeft = windowWidth
    // }

    return (
        <React.Fragment>
            <IconButton className={classes.streetviewButton} onClick={() => setFullscreen(null)} >
                <MapIcon />
            </IconButton>
            {/* <div className={classes.scrollTable} ref={scrollDiv} onScroll={onScroll}> */}
            <InfiniteScroll>
                <table className={classes.panoTable}>
                    <tbody>
                        <tr>{panoPieces}{panoPieces}</tr>
                    </tbody>
                </table>
            </InfiniteScroll>
            {/* </div> */}
        </React.Fragment>
    )
}

export default Streetview