import React, {useState, useEffect, useRef} from "react";
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    scrollTable: {
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: '100%',
        overflowX: "scroll",
        overflowY: "hidden",
        '&::-webkit-scrollbar': {
            display: 'none'
          }
    },
}))

const InfiniteScroll = (props) => {
    const classes = useStyles()
    const scrollDiv = useRef(false)
    const [windowWidth, setWindowWidth] = useState(-1);

    const handleResize = () => setWindowWidth(window.innerWidth)

    useEffect(() => {
        handleResize()
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [])

    const onScroll = e => {
        const currentScrollX = e.target.scrollLeft
        if (scrollDiv && scrollDiv.current && currentScrollX === windowWidth) scrollDiv.current.scrollLeft = 0
        if (scrollDiv && scrollDiv.current && currentScrollX === 0) scrollDiv.current.scrollLeft = windowWidth
    }

    return (

        <div className={classes.scrollTable} ref={scrollDiv} onScroll={onScroll}>
            {props.children}
        </div>
    )
}

export default InfiniteScroll