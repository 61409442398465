// made with help from Shay Keinan tutorial:
// https://engineering.datorama.com/mastering-drag-drop-with-reactjs-part-01-39bed3d40a03
// https://www.youtube.com/watch?v=mbihHtIy67o&t=228s
import React, {useCallback, useEffect} from "react";
import {IconButton} from '@material-ui/core';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import {makeStyles} from '@material-ui/core/styles';

// const POSITION = { x: 0, y: 0 };

const useStyles = makeStyles((theme) => ({
  dragger: {
    backgroundColor: theme.palette.secondary.default,
    position: 'relative',
    top: theme.spacing(),
    left: theme.spacing(),
    height: 10,
    width: 10,
    borderRadius: theme.spacing()
  }
}))

const Dragger = ({dragState, setDragState}) => {
  const classes = useStyles()
  //   const [dragState, setDragState] = useState({
  //     isDragging: false,
  //     offset: POSITION,
  //     position: POSITION,
  //   });

  const handleMouseDown = useCallback(({clientX, clientY}) => {
    const offset = {x: clientX - dragState.position.x, y: clientY - dragState.position.y};
    setDragState(dragState => ({
      ...dragState,
      isDragging: true,
      offset
    }));
  }, [dragState.position]);

  const handleMouseMove = useCallback(({clientX, clientY}) => {
      const x = clientX - dragState.offset.x
      const y = clientY - dragState.offset.y

      const position = {
        x: x < 0 ? 0 : x,
        y: y < 0 ? 0 : y,
      };

      setDragState(dragState => ({
        ...dragState,
        position,
      }));
    },
    [dragState.offset]
  );

  const handleMouseUp = useCallback(() => {
    setDragState(dragState => ({
      ...dragState,
      isDragging: false,
    }));
  }, []);

  const handleTouchStart = useCallback(({nativeEvent: {pageX}, nativeEvent: {pageY}}) => {
    const offset = {
      x: pageX - dragState.position.x,
      y: pageY - dragState.position.y,
    };
    setDragState(dragState => ({
      ...dragState,
      isDragging: true,
      offset,
    }));
  }, [dragState.position]);

  const handleTouchMove = useCallback(
    ({pageX, pageY}) => {
      const position = {
        x: pageX - dragState.offset.x,
        y: pageY - dragState.offset.y,
      };

      setDragState(dragState => ({
        ...dragState,
        position,
      }));
    },
    [dragState.offset]
  );

  const handleTouchEnd = useCallback(() => {
    setDragState(dragState => ({
      ...dragState,
      isDragging: false,
    }));
  }, []);

  useEffect(() => {
    if (dragState.isDragging) {
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);
      window.addEventListener("touchmove", handleTouchMove);
      window.addEventListener("touchend", handleTouchEnd);
      window.addEventListener("contextmenu", handleMouseUp);
    } else {  
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
      window.removeEventListener("touchmove", handleTouchMove);
      window.removeEventListener("touchend", handleTouchEnd);
      window.removeEventListener("contextmenu", handleMouseUp);
    }
  }, [dragState.isDragging]);

  // const styles = useMemo(
  //   () => ({
  //     cursor: dragState.isDragging ? "-webkit-grabbing" : "-webkit-grab"
  //   }),
  //   [dragState.isDragging, dragState.position]
  // );

  return (
    <IconButton style={{cursor: "none"}} size='small'>
      <DragIndicatorIcon
        onMouseDown={dragState.isDragging ? null : handleMouseDown}
        onTouchStart={dragState.isDragging ? null : handleTouchStart}
      />
    </IconButton>
    // <div
    //   className={classes.dragger}
    //   style={styles}
    //   onMouseDown={handleMouseDown}
    //   onTouchStart={handleTouchStart}
    // >
    //   {/* {children} */}
    // </div>
  );
};

export default Dragger;