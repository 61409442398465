import React, {useState, useEffect} from "react";
import {makeStyles} from '@material-ui/core/styles';
import {Container, Grid, Typography} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    demoPhoto: {
        width: 200,
        height: 150
    },
    container: {
        backgroundColor: theme.palette.highlight.default,
        margin: theme.spacing(),
        borderRadius: theme.spacing(),
        width: 200,
        // width: `calc(100% - ${theme.spacing(2)}px)`,
        height: 150
        // maxWidth: 3000,//`calc(100% - ${theme.spacing(8)}px)`,
        // padding: theme.spacing(),
    },
}))

const Demos = () => {
    const classes = useStyles();

    const demos = ["Cistern", "Cologne Cathedral", "Campus", "Architectural Design", "Jefferson Project", "Data", "Carla", "Yael", "Jonas"]
    return (
        <Grid container direction="row" spacing={0} justify='center' alignItems="center">
            <Grid item xs={12}>
                <Container style={{width: '100%', maxWidth: 1000}}>
                    <Typography>DEMOS PAGE</Typography>
                    <Grid container direction="row" spacing={1} justify='center' alignItems="center">
                        {demos.map(d => {
                            return (
                                <Grid item xs={12} sm={6} md={4}>
                                    <Container className={classes.container}>
                                        <Typography>{d}</Typography>
                                    </Container>
                                </Grid>
                            )
                        })}

                    </Grid>
                </Container>
            </Grid>
Î
        </Grid>
    )
}

export default Demos