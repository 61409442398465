import React, {useState, useEffect, useContext} from 'react'
import {makeStyles, useTheme} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
    Avatar, Button, Container, Dialog, DialogContent, DialogContentText,
    DialogTitle, FormControl, Grid, IconButton,
    List, ListItem, ListItemSecondaryAction, ListItemText,
    ListItemAvatar, Menu, MenuItem, TextField, Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
// import VideocamRoundedIcon from '@material-ui/icons/VideocamRounded';
import ScreenShareOutlinedIcon from '@material-ui/icons/ScreenShareOutlined';
import StopScreenShareOutlinedIcon from '@material-ui/icons/StopScreenShareOutlined';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import HelpIcon from '@material-ui/icons/Help';
import Sessions from '../Sessions'
import Entity from '../Entity'
import Streetview from '../Streetview';
import DropZone from '../DropZone'
import InfiniteScroll from '../InfiniteScroll';
import Cursor from '../Cursor'
import axios from "axios";
import {SERVER} from '../../constants/routes'
import config from '../../config'
import {AuthUserContext} from '../Session';
import {SocketContext} from '../../socket'
import ScreenShare from '../ScreenShare';


const useStyles = makeStyles((theme) => ({
    backToSessionButton: {
        zIndex: 10,
        position: 'relative',
        left: theme.spacing(),
        top: theme.spacing()
    },
    icon: {
        color: theme.palette.primary.main
    },
    outerContainer: {
        backgroundColor: theme.palette.background.default,
        borderColor: theme.palette.primary.default,
        color: theme.palette.highlight.default,
        borderWidth: 2,
        borderStyle: 'solid',
        margin: theme.spacing(4),
        borderRadius: theme.spacing(0.5),
        width: `calc(100% - ${theme.spacing(8)}px)`,
        maxWidth: 3000, //`calc(100% - ${theme.spacing(8)}px)`,
        padding: theme.spacing(),
    },
    dialogBox: {
        backgroundColor: theme.palette.primary.main
    },
    dropzone: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: theme.palette.highlight.main,
        borderStyle: 'dashed',
        backgroundColor: theme.palette.background.main,
        color: theme.palette.highlight.main,
        outline: 'none',
        transition: 'border .24s ease-in-out',
        height: 250,
        width: '100%' //`calc(100% - ${theme.spacing(8)}px)`
    },
    hr: {
        width: `calc(100% - ${theme.spacing(4)}px)`,
        height: 1,
        border: 'none',
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.main
    },
    modifierButtons: {
        marginTop: theme.spacing(),
        marginBottom: theme.spacing(2)
    },
    panoramicMediaFullscreen: {
        position: 'fixed',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        zIndex: 1000,
    },
    panoTable: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "200%",
        height: "100%",
        marginBottom: "0rem",
        backgroundColor: "#565656",
        border: "none",
        borderCollapse: "collapse",
    },
    sectionDisplay: {
        display: 'none',
        [theme.breakpoints.up('xl')]: {
            display: 'block',
            background: `repeating-linear-gradient(
                -35deg,
                ${theme.palette.background.default} 0px 19px,
                #454545 19px 20px
                );`,
            width: '100vw',
            height: '100vh'
        },
    },
    sectionDesktop: {
        display: 'block',
        [theme.breakpoints.up('xl')]: {
            display: 'none',
        },
    },
    streetview: {
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
        zIndex: 50,
        position: "absolute"
    },
    td: {
        backgroundPosition: "center",
        backgroundSize: "100% 100%",
        backgroundRepeat: "no-repeat",
        imageRendering: "-moz-crisp-edges", /* Firefox */
        imageRendering: "-o-crisp-edges", /* Opera */
        imageRendering: "-webkit-optimize-contrast", /* Webkit (non-standard naming) */
        transitionDelay: "0.75s",
        transition: "background-image 2s ease-in-out",
        border: "none",
        padding: 0,
        margin: 0
    },
    textField: {
        '& label': {
            color: theme.palette.primary.main,
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: theme.palette.primary.main,
                borderWidth: 2,
            },
            '&:hover fieldset': {
                borderColor: theme.palette.primary.main,
                borderWidth: 2,
            },
        },
        '& input': {
            color: theme.palette.primary.main,
        },
    },
    wideGridItem: {
        width: `calc(100% - ${theme.spacing(8)}px)`

    },
}))



const hostname = `${SERVER}/display`

// ***** THESE SHOULD BE CHANGED TO URL PARAMS ***** //
const paths = {
    postMediaToDisplay: `${hostname}/post_media_to_display`,
    createWebEntity: `${hostname}/create_new_web_entity`,
    getAvailableSessions: `${hostname}/get_available_sessions`,
    getCurrentSession: `${hostname}/get_current_session`,
    createSession: `${hostname}/create_session`,
    removeEntity: `${hostname}/remove_entity`,
    removeSession: `${hostname}/remove_session`,
    updateEntityPostitionInDatabase: `${hostname}/update_entity_position_in_database`
}

const PinUp = () => {
    const classes = useStyles();
    const theme = useTheme();

    const [files, setFiles] = useState([])
    const [axiosConfig, setAxiosConfig] = useState(null)
    const [helpOpen, setHelpOpen] = useState(false)
    const [transform, setTransform] = useState(false)
    const [soundscape, setSoundscape] = useState(false)
    const [grouped, setGrouped] = useState(false)
    const [entities, setEntities] = useState([])
    const [availableSessions, setAvailableSessions] = useState([]);
    const [currentSession, setCurrentSession] = useState(null);
    const [fullscreen, setFullscreen] = useState(false);
    const [isInitiator, setIsInitiator] = useState(null)
    const user = useContext(AuthUserContext)
    const socket = useContext(SocketContext)

    const displayBreakpoint = useMediaQuery(theme.breakpoints.up('xl'));


    useEffect(() => {
        if (socket) {
            socket.on('post_to_display', receivedEntities => {
                addEntities(receivedEntities)
            })
            socket.on('remove_from_display', ({uid, sessionId}) => {
                if (currentSession === sessionId) {
                    const i = entities.findIndex(e => e.file.uid === uid)
                    let tmpEntities = entities
                    tmpEntities[i].hiddenForRemoval = true;
                    setEntities([...tmpEntities])
                }
            })
            return () => {
                socket.off("post_to_display");
                socket.off("remove_from_display");
            };
        }
    }, [socket, currentSession, entities])

    useEffect(() => {
        if (user) {
            setAxiosConfig(prevAxiosConfig => {
                return {
                    ...prevAxiosConfig,
                    headers: {
                        "content-type": "application/json; charset=UTF-8",
                        "authtoken": user.token,
                    },
                    // responseType: "application/json",
                }
            })
        }
    }, [user])

    useEffect(() => {
        if (currentSession) {
            setAxiosConfig(prevAxiosConfig => {return {...prevAxiosConfig, params: {sessionId: currentSession, transform, soundscape, grouped}}})
        }
    }, [currentSession, transform, soundscape, grouped, user])

    useEffect(() => {
        if (axiosConfig) {
            axios
                .get(`${paths.getAvailableSessions}?uid=${user.claims.user_id}`, axiosConfig)
                .then(res => {
                    setAvailableSessions(res.data.sessions)
                })
                .catch(error => console.log('error', error))
        }
    }, [axiosConfig])

    useEffect(() => {
        if (files.length > 0) {
            const formData = new FormData();
            Object.keys(files).forEach(i => {
                formData.append("uploaded_files", files[i])
            })
            // formData.append('sessionId', currentSession)

            axios
                .post(paths.postMediaToDisplay, formData, axiosConfig)
                .then(res => {
                    console.log('res', res);
                })
                .catch(error => console.log('error', error))
        }
        // keep options as opt-in *per* upload
        setTransform(false)
        setSoundscape(false)
        setGrouped(false)
    }, [files])

    const checkStreetviewImage = ({lat, lng, code}) => {
        const pano = lat ? `location=${lat},${lng}` : `pano=${code}`
        const promise = axios.get(`https://maps.googleapis.com/maps/api/streetview/metadata?${pano}&key=${config.google_maps_api_key}`)

        const data = promise.then(res => res.data)
        return data
        // .then(res => {console.log('res[p', res); return res})
    }

    const createNewWebEntity = async address => {
        var tmp;
        var code;
        var mimetype;
        var embedLink;
        var originalname;
        var lat;
        var lng;

        // check if YouTube, Street View, or Website

        //YouTube
        if (address.includes('www.youtube.com/watch?v') || address.includes('youtu.be')) {
            mimetype = 'youtube'
            await axios.get(`https://www.youtube.com/oembed?url=${address}&format=json`)
                .then(res => {
                    console.log('info', res)
                    originalname = res.data.title
                })
            // check for mobile- or desktop-style url
            if (!address.includes('=')) {
                tmp = address.split('/')
                code = tmp[3]
            }
            else {
                tmp = address.split('=')
                tmp = tmp[1]
                tmp = tmp.split('&')
                code = tmp[0]
            }

            embedLink = `https://www.youtube.com/embed/${code}`
        }

        // Street View
        else if (address.includes('google.com/maps') || address.includes('goo.gl/maps') || address.includes('CAoSLEFGMVF')) {
            mimetype = 'streetview'
            if (address.includes('@')) {
                tmp = address.split('@')
                tmp = tmp[1]
                tmp = tmp.split(',')
                lat = tmp[0]
                lng = tmp[1]

                embedLink = {lat, lng}
                originalname = `Street View: ${lat}, ${lng}`
                checkStreetviewImage({lat: lat, lng: lng}).then(data => {
                    if (data.status === "OK") {
                        return
                        // return `Street View: ${lat}, ${lng} by ${data.copyright}`
                    } else {alert('Error finding this panoramic.'); return }
                })
            }
            else if (address.includes('CAoSLEFGMVF')) {
                embedLink = address
                originalname = `Street View: ${address}`
                checkStreetviewImage({code: address}).then(data => {
                    if (data.status === "OK") {
                        return
                        // return `Street View: ${address} by ${data.copyright}`
                    } else {alert('Error finding this panoramic.'); return }
                })
            }
            else {
                alert('Please enter a Street View URL from the desktop browser. Mobile URLs will not work.')
                return
            }
        }

        // Website
        else {
            mimetype = 'website'
            embedLink = address
            originalname = address
        }

        const files = [{path: embedLink, mimetype: mimetype, originalname: originalname}]

        axios.post(`${paths.createWebEntity}`, files, axiosConfig)
            .then(res => {
                console.log('res', res);
            })
            .catch(error => console.log('err', error))
    }


    // listener for enter key on new youtube link
    const textKeyPress = (e) => {
        if (e.keyCode === 13) {
            createNewWebEntity(e.target.value)
        }
    }

    const handleHelpClose = () => {
        setHelpOpen(false);
    };

    const handleSessionChange = (s) => {
        axios
            .get(`${paths.getCurrentSession}?sessionId=${s._id}`, axiosConfig)
            .then(res => {
                console.log('entities', res.data.entities);
                setCurrentSession(res.data._id)
                const ents = res.data.entities.map(e => {
                    e.hiddenForRemoval = false
                    return e
                })
                setEntities(ents)
            })
            .catch(error => console.log('Error retrieving session:', error))
    }

    const addEntities = (receivedEntities) => {
        receivedEntities.files.filter(file =>      // filter out duplicates by uuid
            !entities.some(e => {return e.file.uid === file.uid})
        ).map(file => {
            // add to entity state array
            return setEntities(prevState => [...prevState, {user: receivedEntities.user, sessionId: receivedEntities.sessionId, file: file}])
        })
    }

    const removeEntity = (uid) => {
        console.log('requesting remove of uid:', uid);
        axios.get(`${paths.removeEntity}?uid=${uid}`, axiosConfig)
            .catch(error => console.log('error', error))
    }

    const updateEntityPostitionInDatabase = ({uid, x, y, width, height}) => {
        axios.get(`${paths.updateEntityPostitionInDatabase}?uid=${uid}&x=${x}&y=${y}&width=${width}&height=${height}`, axiosConfig)
            .catch(error => console.log('error updating position', error))
    }

    return (
        <div>
            {currentSession ?
                <React.Fragment >
                    <div className={classes.sectionDesktop}>
                        <Grid container direction="column" justify='flex-start' spacing={1} alignItems="center">
                            <Grid className={classes.wideGridItem} item key="parameter-buttons-item" xs={12}>
                                <Grid container direction="row" justify='space-between' spacing={1} alignItems="center">
                                    <Grid item key="session-return-button-item" xs={5}>
                                        <IconButton className={classes.icon} onClick={() => {setCurrentSession(null); setEntities([])}}>
                                            <ArrowBackRoundedIcon />
                                        </IconButton>
                                    </Grid>
                                    <Grid item key="pinup-title-item" xs={3}>
                                        <Grid container justify='center' align-items='center'>
                                            <Typography color='primary' variant='h4'>Pin-Up</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item key="share-button-item" xs={2}>
                                        <IconButton className={classes.icon} style={{float: 'left'}} onClick={() => setIsInitiator(!isInitiator)}>
                                            {isInitiator ? <StopScreenShareOutlinedIcon /> : <ScreenShareOutlinedIcon />}
                                        </IconButton>
                                    </Grid>
                                    <Grid item key="help-button-item" xs={2}>
                                        <IconButton className={classes.icon} style={{float: 'right'}} onClick={() => setHelpOpen(true)}>
                                            <HelpIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid className={classes.wideGridItem} item key="dropzone-item" xs={12}>
                                <DropZone setFiles={setFiles} />
                            </Grid>
                            <Grid className={classes.wideGridItem} item key="parameter-buttons-item" xs={12}>
                                <Grid container direction="row" justify='center' spacing={1} alignItems="center">
                                    <Grid item key="transform-button-item" xs={4}>
                                        <Grid container justify='center' align-items='center'>
                                            <Button
                                                className={classes.modifierButtons}
                                                variant={transform ? "contained" : "outlined"}
                                                color="primary"
                                                onClick={() => setTransform(!transform)}
                                            >
                                                Transform
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    <Grid item key="soundscape-button-item" xs={4}>
                                        <Grid container justify='center' align-items='center'>
                                            <Button
                                                variant={soundscape ? "contained" : "outlined"}
                                                color="primary"
                                                onClick={() => setSoundscape(!soundscape)}
                                                disabled
                                            >
                                                Soundscape
                                            </Button>
                                        </Grid>

                                    </Grid>
                                    <Grid item key="grouped-button-item" xs={4}>
                                        <Grid container justify='center' align-items='center'>
                                            <Button
                                                variant={grouped ? "contained" : "outlined"}
                                                color="primary"
                                                onClick={() => setGrouped(!grouped)}
                                                disabled
                                            >
                                                Grouped
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid className={classes.wideGridItem} item key="url-input-item" xs={12}>
                                <FormControl style={{width: '100%'}} onSubmit={e => createNewWebEntity(e.target.value)}>
                                    <TextField
                                        variant="outlined"
                                        className={classes.textField}
                                        id="new-youtube-text-field"
                                        label="YouTube, Street View, or Web URL"
                                        onKeyDown={textKeyPress} />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Dialog
                            open={helpOpen}
                            onClose={handleHelpClose}
                            scroll={'paper'}
                            PaperProps={{
                                classes: {
                                    root: classes.dialogBox
                                }
                            }}
                        >
                            <DialogTitle id='help-dialog-title'>Pin-Up Help</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Upload content directly to the CRAIVE screen + audio array.<br />
                                    <br />
                                    Examples of <b>accepted content</b>: <br />
                                    <b>Image</b>  (jpeg, png, tiff) <br />
                                    <b>Video</b>  (mpeg, webm)<br />
                                    <b>Audio</b>   (wav, mp3)<br />
                                    <b>PDF</b><br />
                                    <br />
                                    Use the <b>URL dialog box</b> to upload a <b>YouTube</b> or <b>Street View</b> link
                                    (Simply copy the URL from the target video or image and hit enter) <br />
                                    <br />
                                    The <b>Transform</b> option will apply the transformation for the CRAIVE geometry to a panoramic image.
                                    Requires non-stretched pano with dimensions ≥ 1584x11636px, aspect ratio ≥ 7.345:1.
                                    Currently, the horizon line will default at center vertical pixel.<br />
                                    {/* <br/> */}
                                    {/* The "Soundscape" options will use a visual recognition algorithm to automatically generate a soundscape */}
                                    <br />
                                    Entities on the display can be dragged to new locations.
                                    All uploaded content and their positions will be save automatically.
                                    Sessions are independent from one another.
                                </DialogContentText>
                            </DialogContent>
                        </Dialog>

                        {entities.length > 0 && <Container className={classes.outerContainer}>
                            <List dense className={classes.list}>
                                {entities.map((e, i) => {
                                    return (!e.hiddenForRemoval &&
                                        <div>
                                            {i !== 0 && <hr className={classes.hr} />}
                                            <ListItem key={`entity-list-${i}`}>
                                                <ListItemAvatar>
                                                    <Avatar
                                                        alt={`${e.user.displayName}`}
                                                        src={`${e.user.photoURL}`}
                                                    />
                                                </ListItemAvatar>
                                                <ListItemText id={`entity-user-${i}`} primary={<Typography noWrap>{e.user.displayName} | {e.file.originalname}</Typography>} />
                                                {user && (user.claims.admin || user.claims.manage) &&
                                                    <ListItemSecondaryAction>
                                                        <IconButton
                                                            className={classes.icon}
                                                            edge="start"
                                                            onClick={(event) => {if (window.confirm('Are you sure you wish to delete this entity?')) removeEntity(e.file.uid)}} // event because e defaults
                                                        >
                                                            <CloseIcon />
                                                        </IconButton>
                                                    </ListItemSecondaryAction>
                                                }
                                            </ListItem>
                                        </div>
                                    )
                                })}

                            </List>
                        </Container>
                        }
                    </div>
                    <ScreenShare isInitiator={isInitiator} setIsInitiator={setIsInitiator} />
                    {displayBreakpoint &&
                        <div className={classes.sectionDisplay}>
                            <Cursor />
                            {entities.map((e, i) => (
                                !e.hiddenForRemoval &&
                                <Entity
                                    entity={e}
                                    id={`entity-${i}`}
                                    removeEntity={removeEntity}
                                    setFullscreen={setFullscreen}
                                    updateEntityPostitionInDatabase={updateEntityPostitionInDatabase}
                                    key={`entity-${i}`}
                                />
                            ))}
                            {fullscreen && (fullscreen.lat ?
                                <div className={classes.panoramicMediaFullscreen}>
                                    <Streetview
                                        setFullscreen={setFullscreen}
                                        lat={fullscreen.lat}
                                        lng={fullscreen.lng} />
                                </div>
                                :
                                <div className={classes.panoramicMediaFullscreen}>
                                    <InfiniteScroll >
                                        <table onClick={() => setFullscreen(null)} className={classes.panoTable} style={{height: '100%'}}>
                                            <tbody>
                                                <tr>
                                                    <td
                                                        className={classes.td}
                                                        key={`fullscreen-image-1`}
                                                        id={`fullscreen-image-1`}
                                                        style={{
                                                            backgroundImage: `url(${fullscreen})`
                                                        }}
                                                    />
                                                    <td
                                                        className={classes.td}
                                                        key={`fullscreen-image-2`}
                                                        id={`fullscreen-image-2`}
                                                        style={{
                                                            backgroundImage: `url(${fullscreen})`
                                                        }}
                                                    />
                                                </tr>
                                            </tbody>
                                        </table>
                                    </InfiniteScroll>
                                </div>
                            )}
                        </div>}
                </React.Fragment >
                :
                <Sessions
                    availableSessions={availableSessions}
                    setAvailableSessions={setAvailableSessions}
                    setCurrentSession={setCurrentSession}
                    handleSessionChange={handleSessionChange}
                    axiosConfig={axiosConfig}
                />
            }
        </div >
    )
}

export default PinUp
