import React, {useContext, useEffect, useState} from 'react'
import {Button, Typography} from '@material-ui/core'
import {NavLink} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import {AuthUserContext} from '../Session';
import * as ROUTES from '../../constants/routes';

const useStyles = makeStyles((theme) => ({
    button: {
        textDecoration: 'none',
        background: theme.palette.primary.main,
        color: theme.palette.secondary.main,
        marginRight: theme.spacing(2),
        '&:hover': {
            textDecoration: 'none',
            background: theme.palette.primary.main,
            color: theme.palette.secondary.main,
        }
    },
    link: {
        textDecoration: 'none',
        paddingRight: theme.spacing(2),
        color: theme.palette.primary.main,
        "&:hover": {
            color: theme.palette.highlight.main,
        },
        position: "absolute",
        transform: "translate(-50%, -50%)",
        left: "50%",
        top: theme.spacing(16)

    },
}))


const Landing = () => {
    const classes = useStyles()
    const user = useContext(AuthUserContext)

    return (
        user && <NavLink className={classes.link} to={ROUTES.PIN_UP}><Button className={classes.button}><Typography variant='h6'>Go to Pin-Up</Typography></Button></NavLink>
        // <Typography>Welcome! We are currently under construction!</Typography>
    )
    // const classes = useStyles()
    // const [role, setRole] = useState('')
    // const [users, setUsers] = useState([])
    // const [axiosConfig, setAxiosConfig] = useState(null)
    // const user = useContext(AuthUserContext)

    // useEffect(() => {
    //     if (user) {
    //         console.log('user', user)
    //         // user.getIdToken(/* forceRefresh */ true).then(function (idToken) {
    //         setAxiosConfig(prevAxiosConfig => {
    //             return {
    //                 ...prevAxiosConfig,
    //                 headers: {
    //                     // "content-type": "multipart/form-data",
    //                     "content-type": "application/json; charset=UTF-8",
    //                     "authtoken": user.token,
    //                 },
    //                 // responseType: "application/json",
    //             }
    //         })
    //         // })
    //     }
    // }, [user])

    // // useEffect(() => {
    // //     if (user) {
    // //         user.getIdToken(/* forceRefresh */ true).then(function (idToken) {
    // //             setAxiosConfig(prevAxiosConfig => {
    // //                 return {
    // //                     ...prevAxiosConfig,
    // //                     headers: {
    // //                         // "content-type": "multipart/form-data",
    // //                         "content-type": "application/json; charset=UTF-8",
    // //                         "authtoken": idToken,
    // //                     },
    // //                     // responseType: "application/json",
    // //                 }
    // //             })
    // //         })
    // //     }
    // // }, [user])

    // useEffect(() => {
    //     axios.get(`http://${SERVER}/accounts/`, axiosConfig)
    //         .then(res => {
    //             console.log('res', res);
    //         })
    //         .catch(error => console.log('error', error))
    // }, [axiosConfig])

    // // listener for enter key on new youtube link
    // const textKeyPress = (e) => {
    //     if (e.keyCode === 13) {
    //         setAdmin(e.target.value)
    //     }
    // }

    // const setAdmin = uid => {
    //     if (role === '') return alert("Please select role first");
    //     axios.get(`http://${SERVER}/accounts/set_role/${uid}/${role}`, axiosConfig)
    //         .then(res => {
    //             console.log('res', res);
    //         })
    //         .catch(error => console.log('error', error))
    // }

    // return (
    //     < React.Fragment >
    //         <Typography>Welcome to the CRAIVE-Lab website. We are currently under construction.</Typography>

    //         <FormControl style={{width: '100%'}} onSubmit={e => setAdmin(e.target.value)}>
    //             <TextField
    //                 variant="outlined"
    //                 className={classes.textField}
    //                 id="set-admin-field"
    //                 label="Set Admin by uid"
    //                 onKeyDown={textKeyPress} />
    //         </FormControl>
    //         <FormControl className={classes.formControl}>
    //             <InputLabel id="role-select-label">Role</InputLabel>
    //             <Select
    //                 labelId="role-select-label"
    //                 id="role-select"
    //                 value={role}
    //                 onChange={e => setRole(e.target.value)}
    //             >
    //                 <MenuItem value={'admin'}>Admin</MenuItem>
    //                 <MenuItem value={'manager'}>Manager</MenuItem>
    //                 <MenuItem value={'user'}>User</MenuItem>
    //             </Select>
    //         </FormControl>
    //     </React.Fragment >
    // )
};

export default Landing