import React, {useState, useEffect, useContext, useMemo, useRef} from "react";
import {Avatar, Grid, IconButton, Typography} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {makeStyles} from '@material-ui/core/styles';
import clsx from "clsx";
import {AuthUserContext} from '../Session';
import {SocketContext} from '../../socket'
// import ViewSDKClient from '../../constants/ViewSDKClient'
import {SERVER} from '../../constants/routes'
import Dragger from "../Dragger";
import EntityImage from "../EntityImage";
import EntityAudio from "../EntityAudio";
import EntityVideo from "../EntityVideo";
import EntityPdf from "../EntityPdf"
import EntityIframe from "../EntityIframe"

const useStyles = makeStyles((theme) => ({
    audioBoxed: {
        margin: theme.spacing(2),
        width: `calc( 100% - ${theme.spacing(4)}px)`,
        borderRadius: 0
    },
    avatar: {
        width: theme.spacing(3),
        height: theme.spacing(3)
    },
    entityContainer: {
        backgroundColor: theme.palette.primary.default,
        borderRadius: "8px 8px 2px 8px",
        margin: theme.spacing(),
        width: 600,
        height: 'auto',
        minHeight: 50,
        minWidth: 50,
        overflow: 'hidden',
        position: 'absolute',
    },
    entityTitle: {
        width: '100%',
        height: 'min-content'
    },
    entityContent: {
        height: `calc(100% - ${theme.spacing(4)}px)`
    },
    iconNoCursor: {
        cursor: "none"
    },

}))

const Entity = ({entity, removeEntity, setFullscreen, updateEntityPostitionInDatabase, id, children}) => {
    const classes = useStyles();
    const updatePosition = useRef(false);
    const user = useContext(AuthUserContext)
    const socket = useContext(SocketContext)
    const [resizeDirection, setResizeDirection] = useState("horizontal")
    const entityContainer = useRef(false)

    const displayName = entity.user.displayName
    const photoURL = entity.user.photoURL

    const [containerSize, setContainerSize] = useState({width: 400, height: 'auto'})
    const [dragState, setDragState] = useState({
        isDragging: false,
        offset: {x: 0, y: 0},
        position: {x: 0, y: 0},
    });

    useEffect(() => {
        if (socket) {
            // should this be happening within each entity?
            // this will cause each entity to have its own listener...
            // alternatively, if controlled by parent, will trigger rerender, no ?
            socket.on('update_entity_position', ({sessionId, uid, x, y, width, height}) => {
                if (entity.sessionId === sessionId && entity.file.uid === uid) {
                    updatePosition.current = false
                    const position = {x, y, width, height}
                    setDragState(dragState => ({
                        ...dragState,
                        position,
                    }));
                }
            })

            // socket.on('update_entity_size', ({sessionId, uid, x, y}) => {
            //     if (entity.sessionId === sessionId && entity.file.uid === uid) {
            //         updatePosition.current = false
            //         const size = {x, y}
            //         setDragState(dragState => ({
            //             ...dragState,
            //             position,
            //         }));
            //     }
            // })

            return () => {
                socket.off("update_entity_position");
                // socket.off("update_entity_size");
            }
        }
    }, [socket, dragState])

    useEffect(() => {
        if (entity.position) {
            const position = entity.position
            setDragState(dragState => ({
                ...dragState,
                position,
            }));
            entity.position.width && setContainerSize({width: entity.position.width, height: entity.position.height})
        }
        else {
            const position = {x: 3500, y: 266, width: 600, height: 480}
            setDragState(dragState => ({
                ...dragState,
                position,
            }));
            setContainerSize({width: 600, height: 480})
        }
        // set resize direction: horizontal or both
        if (entity.file &&
            (!entity.file.mimetype
                || entity.file.mimetype === "youtube"
                || entity.file.mimetype === "streetview"
                || entity.file.mimetype === "website"
                || entity.file.mimetype === "application/pdf")) {
            setResizeDirection("both")
        }

        // on container resize, refresh sv to fit
        const resizeObserver = new ResizeObserver(() => {
            entityContainer.current &&
                setContainerSize({width: entityContainer.current.clientWidth, height: entityContainer.current.clientHeight})
        })
        resizeObserver.observe(entityContainer.current)

    }, [])

    // does this need to be a useMemo instead ?
    useEffect(() => {
        if (updatePosition.current && dragState.isDragging === false && updateEntityPostitionInDatabase) {
            const uid = entity.file.uid
            const {x, y} = dragState.position
            const {width, height} = containerSize
            updateEntityPostitionInDatabase({uid, x, y, width, height})
        } else updatePosition.current = true
    }, [dragState, containerSize])


    const styles = useMemo(
        () => ({
            top: dragState.position.y,
            left: dragState.position.x,
            width: containerSize.width,
            height: resizeDirection === 'both' ? containerSize.height : 'auto',
            zIndex: dragState.isDragging ? 2 : 1,
            resize: resizeDirection
            // opacity: .1
        }),
        [dragState.isDragging, dragState.position, resizeDirection, containerSize]
    );


    return (
        <React.Fragment>
            <div className={classes.entityContainer} ref={entityContainer} style={styles}>
                <Grid className={classes.entityTitle} container direction="row"
                    spacing={1} justify='space-between'
                    wrap="nowrap" alignItems="center">
                    <Grid item key="dragger-item" xs={2}>
                        <Dragger
                            dragState={dragState}
                            setDragState={setDragState}
                        />
                    </Grid>
                    <Grid item key="display-name-item" xs={6}>
                        <Grid container direction="row"
                            spacing={1} justify='center'
                            wrap="nowrap" alignItems="center">
                            <Grid item key="dragger-item" xs={3}>
                                {photoURL && <Avatar
                                    className={classes.avatar}
                                    alt={displayName}
                                    src={photoURL}
                                />}
                            </Grid>
                            <Grid item key="entity-display-name-item" xs={9} zeroMinWidth>
                                <Typography noWrap variant="h6" align='center'>{displayName}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item key="close-item" xs={2}>
                        {user && (user.claims.admin || user.claims.manage) &&
                            <IconButton
                                className={classes.iconNoCursor}
                                size='small'
                                style={{float: 'right'}}
                                edge="start"
                                onClick={() => {if (window.confirm('Are you sure you wish to delete this entity?')) removeEntity(entity.file.uid)}}
                            >
                                <CloseIcon />
                            </IconButton>
                        }
                    </Grid>
                </Grid>
                <Grid className={classes.entityContent} container justify='center' align-items='center'>
                    {(entity.file.mimetype === 'image/jpeg'
                        || entity.file.mimetype === 'image/png')
                        && <EntityImage file={entity.file} setFullscreen={setFullscreen} />}

                    {(entity.file.mimetype === 'video/webm'
                        || entity.file.mimetype === 'video/mpeg'
                        || entity.file.mimetype === 'video/mp4')
                        && <EntityVideo file={entity.file} />}

                    {(entity.file.mimetype === 'audio/mpeg'
                        || entity.file.mimetype === 'audio/mpeg')
                        && <EntityAudio file={entity.file} />}

                    {entity.file.mimetype === 'application/pdf'
                        && <EntityPdf file={entity.file} />}

                    {(entity.file.mimetype === 'youtube'
                        || entity.file.mimetype === 'streetview'
                        || entity.file.mimetype === 'website')
                        && <EntityIframe file={entity.file} setFullscreen={setFullscreen}
                        />}
                    {children}
                </Grid>
            </div>
        </React.Fragment >
    );
};

export default Entity;
