import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {IconButton} from "@material-ui/core"
import StreetviewIcon from '@material-ui/icons/Streetview';
import config from "../../config";
import GoogleMapReact from "google-map-react";


const useStyles = makeStyles((theme) => ({
    root: {
        width: "100px",
        height: "100px",
        '&:hover': {
            width: "350px",
            height: "350px",
        },
        transition: 'width .2s, height .2s',
        position: 'absolute',
        bottom: theme.spacing(3),
        right: theme.spacing(3),
        borderRadius: theme.spacing(),
        zIndex: 4,
        overflow: "hidden",
    },
    streetviewButton: {
        width: 30,
        height: 30,
        zIndex: 4,
        position: "absolute",
        top: theme.spacing(7),
        right: theme.spacing(3),
        borderRadius: theme.spacing(),
        backgroundColor: theme.palette.primary.default,
        cursor: "none"
    }
}));

const GoogleMap = ({lat, lng, panoContainer, setFullscreen}) => {
    const classes = useStyles()

    const getOptions = () => {
        return {
            // disableDefaultUI: false,
            fullscreenControl: false,
            streetViewControl: false,
        };
    };

    const apiIsLoaded = (map, maps) => {
        if (map && lat) {
            const panorama = new maps.StreetViewPanorama(panoContainer, {
                // position: { lat: 42.726884, lng: -73.692533 },
                // position: { lat: 31.208505, lng: 121.468125 },
                position: {lat: parseFloat(lat), lng: parseFloat(lng)},
                pov: {
                    heading: 0,
                    pitch: 0
                },
                // motionTrackingControl: true,
                linksControl: false,
                zoomControl: false,
                panControl: false,
                fullscreenControl: false,
                clickToGo: false,
                disableDefaultUI: true,
            });

            // on container resize, refresh sv to fit
            const resizeObserver = new ResizeObserver(() => {
                maps.event.trigger(panorama, 'resize')
            })
            resizeObserver.observe(panoContainer)
        }
    };

    return (
        <React.Fragment>
            <IconButton className={classes.streetviewButton} onClick={() => setFullscreen({lat, lng})}><StreetviewIcon /></IconButton>
            <div className={classes.root}>
                <GoogleMapReact
                    bootstrapURLKeys={{key: config.google_maps_api_key}}
                    defaultCenter={{lat: parseFloat(lat), lng: parseFloat(lng)}}
                    defaultZoom={14}
                    options={getOptions}
                    yesIWantToUseGoogleMapApiInternals
                    onGoogleApiLoaded={({map, maps}) => apiIsLoaded(map, maps)}
                />
            </div>
        </React.Fragment>
    )
}

export default GoogleMap