import React from 'react';

import AuthUserContext from './context';
import {withFirebase} from '../Firebase';

const withAuthentication = Component => {
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        authUser: null,
        idToken: null,
        user: null
      };
    }

    componentDidMount() {
      this.listener = this.props.firebase.auth.onAuthStateChanged(
        authUser => {
          authUser && authUser.getIdTokenResult(true).then(user => {
            this.setState({user})
          })
            // ? this.setState({authUser})
            // : this.setState({authUser: null});
        },
      );
    }


    // componentDidMount() {
    //   this.listener = this.props.firebase.auth.onAuthStateChanged(
    //     authUser => {
    //       authUser
    //         ? this.setState({authUser})
    //         : this.setState({authUser: null});
    //     },
    //   );
    // }

    componentWillUnmount() {
      this.listener();
    }

    render() {
      return (
        <AuthUserContext.Provider value={this.state.user}>
          <Component {...this.props} />
        </AuthUserContext.Provider>
      );
    }
  }

  return withFirebase(WithAuthentication);
};

export default withAuthentication;