import React from "react";
import {makeStyles} from '@material-ui/core/styles';
import {SERVER} from '../../constants/routes'

const useStyles = makeStyles((theme) => ({
    audioBoxed: {
        margin: theme.spacing(2),
        width: `calc( 100% - ${theme.spacing(4)}px)`,
        borderRadius: 0
    },
}))

const EntityAudio = ({file}) => {
    const classes = useStyles();
    const host = `${SERVER}/`;
    const fullSource = host + file.path;

    return (
        <audio
            id={file.originalname}
            className={classes.audioBoxed}
            src={fullSource}
            controls
        />
    )
}

export default EntityAudio;
