import React, {useEffect, useState} from "react";
import {makeStyles} from '@material-ui/core/styles';
import {Grid} from '@material-ui/core'
import ViewSDKClient from '../../constants/ViewSDKClient'
import {SERVER} from '../../constants/routes'

const useStyles = makeStyles((theme) => ({
    pdfDiv: {
        width: "100%",
        height: "100%"
    },
    wideGridItem: {
        width: '100%',
        height: '100%'
    },
}))

const EntityPdf = ({file}) => {
    const classes = useStyles();

    useEffect(() => {
        const viewSDKClient = new ViewSDKClient();
        viewSDKClient.ready().then(() => {
            viewSDKClient.previewFile(
                `pdf-div-${file.uid}`,
                `${SERVER}/${file.path}`,
                file.originalname,
                file.uid, {
                /* Pass the embed mode option here */
                embedMode: "SIZED_CONTAINER"
            });
        });
    }, [])

    return (
        <Grid className={classes.wideGridItem} container justify='center' align-items='center'>
            <Grid item className={classes.wideGridItem}>
                <div className={classes.pdfDiv} id={`pdf-div-${file.uid}`} />
            </Grid>
        </Grid>
    )
}

export default EntityPdf;
