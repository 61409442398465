import React, {useEffect, useRef} from "react";
import {makeStyles} from '@material-ui/core/styles';
import GoogleMap from '../GoogleMap'
// import {SERVER} from '../../constants/routes'

const useStyles = makeStyles((theme) => ({
    panoContainer: {
        position: 'relative',
        zIndex: 0,
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        minHeight: 218 //minHeight of 250,  - 32 for title
    }
}))

const EntityIframe = ({file, setFullscreen}) => {
    const classes = useStyles();
    const panoContainer = useRef();

    return (
        file.mimetype === 'streetview' ?
            <React.Fragment>
                <div ref={panoContainer} className={classes.panoContainer} />
                <GoogleMap
                    panoContainer={panoContainer.current}
                    setFullscreen={setFullscreen}
                    lat={file.path.lat}
                    lng={file.path.lng}
                />
            </React.Fragment>
            :
            <iframe
                width="100%"
                height='auto'
                src={file.path}
                frameborder="0"
                allowFullScreen
                title={file.originalname}
            />

    )
}

export default EntityIframe;
