import React from 'react';
import {Typography} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles';

import {withFirebase} from '../Firebase';

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: 'none',
    paddingRight: theme.spacing(2),
    color: theme.palette.primary.main
  },
  menuLink: {
    textDecoration: 'none',
  }
}))

const SignOutButton = ({firebase}) => {
  const classes = useStyles();
  return (
    <Typography className={classes.menuLink} onClick={firebase.doSignOut}>Sign Out</Typography>
  )
}

export default withFirebase(SignOutButton);