import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    cursor: {
        width: 40,
        height: 40,
        border: `4px solid ${theme.palette.primary.main}`,
        borderRadius: "100%",
        position: "fixed",
        transform: "translate(-50%, -50%)",
        pointerEvents: 'none',
        zIndex: 9999,
        transition: 'all 150ms ease',
        transitionProperty: 'opacity',
    },
    cursorHidden: {opacity: 0},
    cursorClicked: {
        transform: 'translate(-50%, -50%) scale(0.85)',
        backgroundColor: theme.palette.primary.main
    }
}))

const Cursor = () => {
    const classes = useStyles();

    const [position, setPosition] = useState({x: 0, y: 0});
    const [clicked, setClicked] = useState(false);
    const [hidden, setHidden] = useState(false);

    useEffect(() => {
        addEventListeners();
        return () => removeEventListeners();
    }, []);

    const addEventListeners = () => {
        document.addEventListener("mousemove", onMouseMove);
        document.addEventListener("mouseenter", onMouseEnter);
        document.addEventListener("mouseleave", onMouseLeave);
        document.addEventListener("mousedown", onMouseDown);
        document.addEventListener("mouseup", onMouseUp);
    };

    const removeEventListeners = () => {
        document.removeEventListener("mousemove", onMouseMove);
        document.removeEventListener("mouseenter", onMouseEnter);
        document.removeEventListener("mouseleave", onMouseLeave);
        document.removeEventListener("mousedown", onMouseDown);
        document.removeEventListener("mouseup", onMouseUp);
    };

    const onMouseMove = (e) => {
        setPosition({x: e.clientX, y: e.clientY});
    };

    const onMouseDown = () => {
        setClicked(true);
    };

    const onMouseUp = () => {
        setClicked(false);
    };

    const onMouseLeave = () => {
        setHidden(true);
    };

    const onMouseEnter = () => {
        setHidden(false);
    };

    return (
        <div
            className={`${classes.cursor} ${clicked && classes.cursorClicked} ${hidden && classes.cursorHidden}`}
            style={{left: `${position.x}px`, top: `${position.y}px`}}
        />
    );
};

export default Cursor