// made with code from tutorial by Binh Tran:
// https://medium.com/@thanhbinh.tran93/private-route-public-route-and-restricted-route-with-react-router-d50b27c15f5e
import React, {useContext} from 'react';
import { Route, Redirect } from 'react-router-dom';
import {AuthUserContext} from '../Session'
import * as ROUTES from '../../constants/routes';

const PublicRoute = ({component: Component, restricted, ...rest}) => {
    const user = useContext(AuthUserContext)

    return (
        // restricted = false meaning public route
        // restricted = true meaning restricted route
        <Route {...rest} render={props => (
            user && restricted ?
                <Redirect to="/" />
            : <Component {...props} />
        )} />
    );
};

export default PublicRoute;