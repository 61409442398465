import React, {useState, useEffect, useContext} from 'react'
import {makeStyles} from '@material-ui/core/styles';
import {
    Button, Container, FormControl, Grid, IconButton, LinearProgress,
    List, ListItem, ListItemSecondaryAction, ListItemText, TextField, Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import axios from "axios";
import {SERVER} from '../../constants/routes'
import {AuthUserContext} from '../Session';
import {SocketContext} from '../../socket'

const useStyles = makeStyles((theme) => ({


    gridRoot: {
        flexGrow: 1
    },
    hr: {
        width: `calc(100% - ${theme.spacing(4)}px)`,
        height: 1,
        border: 'none',
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.main
    },
    icon: {
        color: theme.palette.primary.main
    },
    loader: {
        marginTop: theme.spacing(5),
        width: '50%',
        colorPrimary: {
            backgroundColor: theme.palette.secondary.main
        },
        bar: {
            borderRadius: 5,
            backgroundColor: theme.palette.primary.main,

        }
    },
    outerContainer: {
        backgroundColor: theme.palette.background.default,
        borderColor: theme.palette.primary.default,
        color: theme.palette.highlight.default,
        borderWidth: 2,
        borderStyle: 'solid',
        margin: theme.spacing(4),
        borderRadius: theme.spacing(0.5),
        width: `calc(100% - ${theme.spacing(8)}px)`,
        maxWidth: 3000,
        padding: theme.spacing(),
    },
    submitButton: {
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: 'fit-content',
        },
        float: 'right'
    },
    textStyle: {
        color: theme.palette.primary.main
    },
    textField: {
        '& label': {
            color: theme.palette.primary.main,
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: theme.palette.primary.main,
                borderWidth: 2,
            },
            '&:hover fieldset': {
                borderColor: theme.palette.primary.main,
                borderWidth: 2,
            },
        },
        '& input': {
            color: theme.palette.primary.main,
        },
    },
    textFieldNewPinUp: {
        '& label': {
            color: '#69ff69',
        },
        '& label.Mui-focused': {
            color: '#69ff69',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: theme.palette.primary.main,
            borderWidth: 1
        },
        '& .MuiInput-underline:before': {
            borderBottomColor: theme.palette.primary.main,
            borderWidth: 1
        },
        '& .MuiInput-underline:hover::before': {
            borderColor: theme.palette.primary.main,
            borderWidth: 1
        }
    },
}))

const hostname = `${SERVER}/display`

const paths = {
    postMediaToDisplay: `${hostname}/post_media_to_display`,
    createYoutubeEntity: `${hostname}/create_new_youtube_entity`,
    getAvailableSessions: `${hostname}/get_available_sessions`,
    getCurrentSession: `${hostname}/get_current_session`,
    createSession: `${hostname}/create_session`,
    removeEntity: `${hostname}/remove_entity`,
    removeSession: `${hostname}/remove_session`,
    updateEntityPostitionInDatabase: `${hostname}/update_entity_position_in_database`
}

const Sessions = ({availableSessions, setAvailableSessions, setCurrentSession, handleSessionChange, axiosConfig}) => {
    const classes = useStyles();

    const user = useContext(AuthUserContext)
    const socket = useContext(SocketContext)

    const [newSessionName, setNewSessionName] = useState('');
    const handleNewSessionNameChange = (event) => {
        setNewSessionName(event.target.value);
    };

    useEffect(() => {
        if (socket) {
            socket.on('remove_session', _id => {
                setAvailableSessions(availableSessions.filter(s => s._id !== _id))
            })

            return () => {
                socket.off("remove_session");
            };
        }
    }, [socket, availableSessions])

    const removeSession = (_id) => {
        axios.get(`${paths.removeSession}?_id=${_id}`, axiosConfig)
            .then(res => {
                console.log('res', res);
            })
            .catch(error => console.log('error', error))
    }
    const createNewSession = () => {
        axios.get(`${paths.createSession}?newSessionName=${newSessionName}`, axiosConfig)
            .then(res => {
                console.log('res', res);
                setCurrentSession({name: newSessionName})
                setAvailableSessions(prevArray => [...prevArray, {name: newSessionName}])
            })
            .catch(error => alert("Session name already be taken!"))
    }

    const textKeyPress = (e) => {
        if (e.keyCode === 13) {
            createNewSession()
        }
    }

    return (
        availableSessions.length > 0 ?
            <div>
                <Typography className={classes.textStyle} style={{marginLeft: 54, marginTop: 32}}>Choose a saved session or begin a new one!</Typography>
                <Container className={classes.outerContainer}>
                    <List dense className={classes.list}>
                        {availableSessions.map((s, i) => {
                            return (
                                <div>
                                    {i !== 0 && <hr className={classes.hr} />}
                                    <ListItem key={`session-item-${i}`} onClick={(event) => handleSessionChange(s)}>
                                        <ListItemText id={`session-text-${i}`} primary={`${s.name}`} />
                                        {user && (user.claims.admin || user.claims.manage) &&
                                            <ListItemSecondaryAction>
                                                <IconButton
                                                    className={classes.icon}
                                                    edge="start"
                                                    onClick={(event) => {if (window.confirm('Are you sure you wish to delete this session?')) removeSession(s._id)}}
                                                >
                                                    <CloseIcon />
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        }
                                    </ListItem>
                                </div>
                            )
                        })}
                        <div>
                            <hr className={classes.hr} />
                            <ListItem key={`new-session-list-item`}>
                                <FormControl className={classes.gridRoot} onSubmit={(event) => createNewSession(newSessionName)}>
                                    <Grid container direction="row"
                                        spacing={1} justify='space-between' alignItems="center">
                                        <Grid item key="new-session-text-field-grid-item" xs={12} sm={10}>
                                            <TextField
                                                className={`${classes.textField} ${classes.textFieldNewPinUp}`}
                                                id="new-session-text-field"
                                                label='New Pin-Up'
                                                value={newSessionName}
                                                onChange={handleNewSessionNameChange}
                                                onKeyDown={textKeyPress}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item key="new-session-submit-grid-item" justify='center' xs={12} sm={2}>
                                            <Button
                                                className={classes.submitButton}
                                                variant={newSessionName.length < 4 ? "outlined" : "contained"}
                                                color="primary"
                                                type='submit' onClick={createNewSession}
                                                disabled={newSessionName.length < 4}
                                            >
                                                Create
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </FormControl>
                            </ListItem>
                        </div>
                    </List>
                </Container>
            </div >
            :
            <Grid container justify='center' align-items='center'>
                <LinearProgress className={classes.loader} />
            </Grid>
    )
}

export default Sessions