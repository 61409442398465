import React from "react";
import {makeStyles} from '@material-ui/core/styles';
import {SERVER} from '../../constants/routes'

const useStyles = makeStyles((theme) => ({
    video: {
        maxWidth: "100%",
        maxHeight: "100%"
    },
}))

const EntityVideo = ({file}) => {
    const classes = useStyles();
    const host = `${SERVER}/`;
    const fullSource = host + file.path;

    return (
        <video
            autoPlay={file.autoplay}
            id={file.originalname}
            className={classes.video}
            src={fullSource}
            controls
        />
    )
}

export default EntityVideo;
