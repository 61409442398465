const config = {
    firebase: {
        apiKey: "AIzaSyDuEMX7sj2r-xjpM1dsxN-DOzOURWU0n18",
        authDomain: "craiveco.firebaseapp.com",
        projectId: "craiveco",
        storageBucket: "craiveco.appspot.com",
        messagingSenderId: "608751509562",
        appId: "1:608751509562:web:331434a1a8f734af06d503",
        measurementId: "G-8YLSK5J3VF"
    },
    google_maps_api_key: "AIzaSyCHly4T1Wbs-qPcWs3yuiY8TxFGfMUYRPA",
    adobe_sdk_id: "9b3004ad2c4b4bdd92e57c803beb61bd",
}

export default config