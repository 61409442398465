export const LANDING = '/';
// export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const HOME = '/home';
export const DASHBOARD = '/dashboard';
export const DEMOS = '/demos';
export const PIN_UP = '/pin_up';
export const STREETVIEW = '/streetview';
export const MANAGE = '/manage';

// export const SERVER = 'localhost:8081'

export const SERVER = 'https://samuelchabot.com'

// export const SERVER = '129.161.132.216:8081'
