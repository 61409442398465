import React, {useState} from "react";
import {makeStyles} from '@material-ui/core/styles';
import {SERVER} from '../../constants/routes'

const useStyles = makeStyles((theme) => ({
    image: {
        maxWidth: "100%",
        maxHeight: "100%"
    }
}))

const EntityImage = ({file, setFullscreen}) => {
    const classes = useStyles();
    const host = `${SERVER}/`;
    const fullSource = host + file.path;
    const [imageDimensions, setImageDimensions] = useState({height: null, width: null})

    const onImageLoad = ({target: img}) => {
        setImageDimensions({width: img.naturalWidth, height: img.naturalHeight});
    }

    const makeFullscreen = (e) => {
        // if wide aspect ratio, assume pano
        if (imageDimensions.width / imageDimensions.height > 8) {
            setFullscreen(fullSource)
        }
    }

    return (
        <img
            className={classes.image}
            src={fullSource}
            alt={file.originalname}
            onLoad={onImageLoad}
            onClick={makeFullscreen}
        />
    )
}

export default EntityImage;
