import React, {useContext, useEffect, useState} from 'react'
import {Container, IconButton, List, ListItem, ListItemText, ListItemSecondaryAction} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import {makeStyles} from '@material-ui/core/styles';
import {AuthUserContext} from '../Session';
import {SERVER} from '../../constants/routes'
import axios from "axios";

const useStyles = makeStyles((theme) => ({
    outerContainer: {
        backgroundColor: theme.palette.background.default,
        borderColor: theme.palette.primary.default,
        color: theme.palette.highlight.default,
        borderWidth: 2,
        borderStyle: 'solid',
        margin: theme.spacing(4),
        borderRadius: theme.spacing(0.5),
        width: `calc(100% - ${theme.spacing(8)}px)`,
        maxWidth: 3000,
        padding: theme.spacing(),
    },
    icon: {
        color: theme.palette.primary.main
    },
    textField: {
        '& label': {
            color: theme.palette.primary.main,
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: theme.palette.primary.main,
                borderWidth: 2,
            },
            '&:hover fieldset': {
                borderColor: theme.palette.primary.main,
                borderWidth: 2,
            },
        },
        '& input': {
            color: theme.palette.primary.main,
        },
    },
}))


const Manage = () => {
    const classes = useStyles()
    const [role, setRole] = useState('')
    const [users, setUsers] = useState([])
    const [axiosConfig, setAxiosConfig] = useState(null)
    const user = useContext(AuthUserContext)

    useEffect(() => {
        if (user) {
            console.log('user', user)
            // user.getIdToken(/* forceRefresh */ true).then(function (idToken) {
            setAxiosConfig(prevAxiosConfig => {
                return {
                    ...prevAxiosConfig,
                    headers: {
                        // "content-type": "multipart/form-data",
                        "content-type": "application/json; charset=UTF-8",
                        "authtoken": user.token,
                    },
                    // responseType: "application/json",
                }
            })
            // })
        }
    }, [user])

    // useEffect(() => {
    //     if (user) {
    //         user.getIdToken(/* forceRefresh */ true).then(function (idToken) {
    //             setAxiosConfig(prevAxiosConfig => {
    //                 return {
    //                     ...prevAxiosConfig,
    //                     headers: {
    //                         // "content-type": "multipart/form-data",
    //                         "content-type": "application/json; charset=UTF-8",
    //                         "authtoken": idToken,
    //                     },
    //                     // responseType: "application/json",
    //                 }
    //             })
    //         })
    //     }
    // }, [user])

    useEffect(() => {
        axios.get(`${SERVER}/firebase/`, axiosConfig)
            .then(res => {
                console.log('users', res.data);
                setUsers(res.data)
            })
            .catch(error => console.log('Error fetching users:', error))
    }, [axiosConfig])

    // listener for enter key on new youtube link
    const textKeyPress = (e) => {
        if (e.keyCode === 13) {
            setAdmin(e.target.value)
        }
    }

    const setAdmin = uid => {
        if (role === '') return alert("Please select role first");
        axios.get(`${SERVER}/firebase/set_role/${uid}/${role}`, axiosConfig)
            .then(res => {
                console.log('res', res);
            })
            .catch(error => console.log('error', error))
    }

    const removeUser = (uid) => {
        console.log('This will remove user:', uid);
    }

    return (
        <Container className={classes.outerContainer}>
            <List className={classes.list}>
                {users.map((u, i) => {
                    return (
                        <div>
                            {i !== 0 && <hr className={classes.hr} />}
                            <ListItem key={`session-item-${i}`}>
                                <ListItemText id={`session-text-${i}`} primary={`${u.displayName}`} />
                                <ListItemSecondaryAction>
                                    <IconButton
                                        disabled={!(user.claims.admin || user.claims.manager)}
                                        className={classes.icon}
                                        edge="start"
                                        onClick={(event) => {if (window.confirm('Are you sure you wish to delete this session?')) removeUser(u.uid)}}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                                <ListItemSecondaryAction>
                                    <IconButton
                                        disabled={!(user.claims.admin)}
                                        className={classes.icon}
                                        edge="start"
                                        onClick={(event) => {if (window.confirm('Are you sure you wish to delete this session?')) removeUser(u.uid)}}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        </div>
                    )
                })}
            </List>
        </Container>

    )
};

export default Manage