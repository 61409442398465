// import socketio from "socket.io-client";
import React, {useState, useEffect, useContext} from 'react'
import io from "socket.io-client";
import {AuthUserContext} from './Components/Session'
import {SERVER} from './constants/routes'

const serverAddress = `${SERVER}`;

export const WithSocket = ({children}) => {
    const user = useContext(AuthUserContext)
    const [socket, setSocket] = useState(null)
    useEffect(() => {
        if (user) {
            // user.getIdToken(/* forceRefresh */ true).then(function (idToken) {
            setSocket(io(serverAddress, {query: {firebase: user.token}}))
            // })
        }
    }, [user])

    // useEffect(() => {
    //     if (user) {
    //         user.getIdToken(/* forceRefresh */ true).then(function (idToken) {
    //             setSocket(io(serverAddress, {query: {firebase: idToken}}))
    //         })
    //     }
    // }, [user])

    return (
        <SocketContext.Provider value={socket}>
            {children}
        </SocketContext.Provider>
    )
}

export const SocketContext = React.createContext();