import React, {useState, useContext, useEffect} from 'react'
import {makeStyles} from '@material-ui/core/styles';
import {
    AppBar, Box, Button, Container,
    FormControl, FormControlLabel, FormGroup, FormLabel,
    Slider, Switch, Tab, Tabs, Typography
} from '@material-ui/core'
import {SocketContext} from '../../socket'

const useStyles = makeStyles((theme) => ({
    box: {

    },
    labelText: {
        color: theme.palette.secondary.main
    },
    switchRoot: {
        margin: theme.spacing(2),
    },
    colorSliderContainer: {
        height: 200
    },
    colorSlider: {
        color: '#ac3321',
    },
    lightContainter: {
        backgroundColor: theme.palette.primary.default,
        borderRadius: theme.spacing(2),
        margin: theme.spacing(4),
        width: `calc(100% - ${theme.spacing(8)}px)`,
    },
    outerContainer: {
        backgroundColor: theme.palette.primary.default,
        margin: theme.spacing(4),
        borderRadius: theme.spacing(2),
        width: `calc(100% - ${theme.spacing(8)}px)`,
        maxWidth: 3000,//`calc(100% - ${theme.spacing(8)}px)`,
        padding: theme.spacing(),
    },

    // sectionDisplay: {
    //     display: 'none',
    //     [theme.breakpoints.up('xl')]: {
    //         display: 'block',
    //     },
    // },
    // sectionDesktop: {
    //     display: 'block',
    //     [theme.breakpoints.up('xl')]: {
    //         display: 'none',
    //     },
    // },
}))

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3} color='grey'>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}


const Dashboard = () => {
    const classes = useStyles();
    const socket = useContext(SocketContext)
    const [tabValue, setTabValue] = React.useState(0);


    const handleTabValueChange = (event, newTabValue) => {
        setTabValue(newTabValue);
    };

    const [projectorState, setProjectorState] = useState({
        power: false,
        blank: false
    });


    useEffect(() => {
        socket.on('projectors_update', update => {

            if (update.command === 'power' && update.value === false) {
                setProjectorState({power: false, blank: false});
            }
            else {setProjectorState({...projectorState, [update.command]: update.value});}
        })
        return () => {
            socket.off("projectors_update");
        };
    }, [socket, projectorState])

    const handleSwitchChange = (event) => {
        const command = event.target.name
        const value = event.target.checked

        if (command === 'power' && value === false) {
            setProjectorState({...projectorState, power: false, blank: false});
        }
        else {
            setProjectorState({...projectorState, [command]: value});
        }
        socket.emit("projectors_update", {command: command, value: value})

    };

    const handleSliderChange = (event, value) => {

    }

    return (
        <div>
            <Container className={classes.outerContainer}>
                <FormControl component="fieldset" style={{padding: '100px'}}>
                    <FormLabel><Typography className={classes.labelText}>Projectors:</Typography></FormLabel>
                    <FormGroup>
                        <FormControlLabel
                            value="power"
                            control={
                                <Switch
                                    classes={{root: classes.switchRoot}}
                                    checked={projectorState.power}
                                    onChange={handleSwitchChange}
                                    name="power"
                                />
                            }
                            label={<Typography className={classes.labelText}>Power</Typography>}
                            labelPlacement="start"
                        />
                        <FormControlLabel
                            value="blank"
                            control={
                                <Switch
                                    classes={{root: classes.switchRoot}}
                                    disabled={!projectorState.power}
                                    checked={projectorState.blank}
                                    onChange={handleSwitchChange}
                                    name="blank"
                                />
                            }
                            label={<Typography className={classes.labelText}>Blank</Typography>}
                            labelPlacement="start"
                        />
                    </FormGroup>
                </FormControl>
            </Container>
                <AppBar className={classes.outerContainer} position='static'>
                    <Tabs textColor='primary' indicatorColor='secondary' value={tabValue} onChange={handleTabValueChange}>
                        <Tab label="Presets" />
                        <Tab label="Sliders" />
                    </Tabs>
                    <TabPanel value={tabValue} index={0}>
                        <Button>Testing</Button>
                        <Button>Testing</Button>
                        <Button>Testing</Button>
                        <Button>Testing</Button>
                        <Button>Testing</Button>
                        <Button>Testing</Button>
                        <Button>Testing</Button>
                        <Button>Testing</Button>
                        <Button>Testing</Button>
                    </TabPanel>
                    <TabPanel value={tabValue} index={1}>
                        <div className={classes.colorSliderContainer}>
                            <Slider
                                classes={{
                                    root: classes.colorSlider
                                }}
                                defaultValue={0}
                                onChange={handleSliderChange}
                                orientation="vertical"
                            />
                            <Slider
                                defaultValue={0}
                                onChange={handleSliderChange}
                                orientation="vertical"
                            />
                            <Slider
                                defaultValue={0}
                                onChange={handleSliderChange}
                                orientation="vertical"
                            />
                        </div>
                    </TabPanel>
                </AppBar>
        </div>
    )
}

export default Dashboard;